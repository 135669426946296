module.exports = {
	defaultTitle: 'Yassine Bouhouta',
	logo: '/favicon/yassine_bouhouta.png',
	author: 'Yassine Bouhouta',
	firstName: 'Yassine',
	lastName: 'Bouhouta',
	url: 'https://www.yassinebouhouta.com',
	legalName: 'Yassine Bouhouta',
	profession: 'Software Engineer & Full Stack Javascript Developer',
	defaultDescription: 'I’m Yassine and I’m a Software Engineer & Full Stack Javascript Developer.',
	socialLinks: {
		// twitter: 'http://www.twitter.com/smakosh',
		github: 'https://github.com/IamAppDev',
		linkedin: 'https://www.linkedin.com/in/yassine-bouhouta-aa6511154/'
		// instagram: 'https://instagram.com/smakosh19',
		// youtube: 'https://www.youtube.com/user/smakoshthegamer',
		// google: 'https://plus.google.com/u/0/+IsmailSmakoshGhallou',
	},
	googleAnalyticsID: 'G-0DNXGG5JT9',
	publisherId: 'ca-pub-2306281395612390',
	themeColor: '#6b63ff',
	backgroundColor: '#6b63ff',
	social: {
		// facebook: 'appId',
		// twitter: '@smakosh',
		facebook: '',
		twitter: ''
	},
	address: {
		city: 'Marrakech',
		region: 'Tensift Alhaouz',
		country: 'Morocco',
		zipCode: '40000'
	},
	contact: {
		email: 'contact@yassinebouhouta.com',
		phone: 'phone number'
	},
	foundingDate: '2020'
};
